import React from 'react'
import { FaFacebook, FaInstagram, FaLinkedin, FaTiktok, FaYoutube } from 'react-icons/fa'
import { FaX, FaXTwitter } from 'react-icons/fa6'
import { PiFacebookLogo, PiInstagramLogo, PiXLogo } from 'react-icons/pi'
import LogoUdi from '../../assets/Logo-UDI.png'

function Footer() {
    return (
        <div className='w-full bg-bgtext py-10'>
            <div className='container mx-auto flex flex-col md:flex-row gap-10 md:gap-0 justify-between items-center text-white'>
                <div>
                    <img src={LogoUdi} className='w-1/2' />
                    <h2 className='text-4xl font-bold tracking-widest '>
                        Daccarett
                    </h2>
                    <span className='text-lg font-medium tracking-widest'>
                        Concejal Las Condes
                    </span>
                </div>
                <div className='flex gap-2 flex-col md:items-end'>
                    <h2 className='text-lg font-medium tracking-widest '>
                        Redes Sociales
                    </h2>
                    <div className='grid grid-cols-3 gap-10'>
                        <a href='https://www.instagram.com/jorgedaccarettbahna/' target='_blank' className='text-lg font-medium tracking-widest'><FaInstagram size={30} /></a>
                        <a href='https://www.tiktok.com/@daccarettconcejal' target='_blank' className='text-lg font-medium tracking-widest'><FaTiktok size={30} /></a>
                        <a href='https://www.youtube.com/@JorgeDaccarettConcejal' target='_blank' className='text-lg font-medium tracking-widest'><FaYoutube size={30} /></a>
                        <a href='https://www.facebook.com/profile.php?id=61564168745417' target='_blank' className='text-lg font-medium tracking-widest'><FaFacebook size={30} /></a>
                        <a href='https://x.com/j_daccarett' target='_blank' className='text-lg font-medium tracking-widest'>
                            <FaXTwitter size={30} />
                        </a>
                        <a href='https://www.linkedin.com/in/jorgedaccarett/' target='_blank' className='text-lg font-medium tracking-widest'>
                            <FaLinkedin size={30} />
                        </a>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Footer