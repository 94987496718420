import React from 'react'

function PropuestaCard({ title, description }) {
    return (
        <div className='w-full border-bgtext border p-5 items-center flex flex-col gap-4 justify-between rounded-xl 2xl:h-[15rem] h-[20rem]'>
            <h1 className='text-2xl font-bold text-center'>{title}</h1>
            <p className='text-center'>
                {description}
            </p>

        </div>
    )
}

export default PropuestaCard