import React from 'react'
import ImagesComponents from '../../images'

function HeroSection() {
    return (
        <div className="bg-background h-screen w-full relative overflow-hidden ">
            {/* <div className="w-full  absolute">
        <img src={FondoDacarett} alt="Fondo con Daccarett" />
        </div> */}
            <ImagesComponents />
            <div className="max-w-[1200px] mx-auto flex flex-col md:flex-row justify-between md:justify-center pt-10 md:items-center  h-full ">

                <div className="bg-bgtext text-white flex flex-col gap-2 text-center h-min md:px-10 px-5 py-5 -rotate-3   ">
                    <h4 className="text-base md:text-2xl rotate-2 tracking-widest font-normal">
                        LA TRAYECTORIA SE RECONOCE
                    </h4>
                    <h1 className="text-3xl md:text-6xl font-bold tracking-widest rotate-2">
                        DACCARETT
                    </h1>
                    <h4 className="rotate-2 tracking-widest text-xl">
                        CONCEJAL
                    </h4>
                </div>
                <img src={'https://s3.sa-east-1.amazonaws.com/imagenes.simple/jorgedaccarett/Daccarett_figura.webp'} alt='jorge' className='2xl:w-full md:w-[50%]' />
            </div>
        </div>
    )
}

export default HeroSection