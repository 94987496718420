import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css'
import PropuestaCard from '../PropuestaCard';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';




function MetasSection() {
    return (
        <div className='w-full py-28 p-4 bg-[#f3f3f3]'>
            <div className='container mx-auto flex flex-col gap-5 items-center justify-center '>
                <div className="bg-bgtext text-white p-5 whitespace-nowrap  -rotate-3">
                    <h1 className="text-3xl md:text-6xl font-bold tracking-widest ">
                        Propuestas
                    </h1>
                </div>
                <div className='w-full mt-10'>
                    <Swiper
                        modules={[Navigation, Autoplay, Pagination]}
                        loop={true}
                        spaceBetween={50}
                        autoplay={{ delay: 1000, disableOnInteraction: false }}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                        navigation
                        Pagination={{ clickable: true }}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50,
                            },
                        }}
                    >
                        <SwiperSlide><PropuestaCard title={'Emprendimiento Exportador.'} description={'Apoyaremos a nuestros emprendedores y emprendedoras a poder internacionalizarse, capacitándolos en los fondos concursables Corfo y ProChile, y con talleres de acceso a mercados de exportación y con el apoyo y patrocinio de las embajadas presentes en la comuna.s'} /></SwiperSlide>
                        <SwiperSlide><PropuestaCard title={'Educación Bilingüe.'} description={'Promoveremos la educación bilingüe en los colegios municipales a nivel de educación primaria, como una herramienta para que los niños y jóvenes de la comuna tengan más acceso a su desarrollo'} /></SwiperSlide>
                        <SwiperSlide><PropuestaCard title={'Mejores Prácticas Globales en Seguridad Ciudadana y Smart Cities.'} description={'Se buscará la firma de acuerdos internacionales para incorporar en la comuna la mejor tecnología y políticas a nivel mundial en estas materias, para el beneficio y comodidad de los vecinos.'} /></SwiperSlide>
                        <SwiperSlide><PropuestaCard title={'Mejores Prácticas Globales en Seguridad Ciudadana y Smart Cities.'} description={'Se buscará la firma de acuerdos internacionales para incorporar en la comuna la mejor tecnología y políticas a nivel mundial en estas materias, para el beneficio y comodidad de los vecinos.'} /></SwiperSlide>


                    </Swiper>
                </div>

            </div>

        </div>
    )
}

export default MetasSection