import React from 'react'
import Jorge from '../../assets/IMG_7526.jpg'

function AboutUsSection() {
    return (
        <div className='w-full py-10 p-4 bg-[#f3f3f3]'>
            <div className='container mx-auto flex flex-col md:flex-row  gap-10 items-center justify-center '>
                <div className="bg-bgtext text-white p-5 whitespace-nowrap rotate-3 flex gap-3 flex-col">
                    <img src={Jorge} alt='jorge' className='w-80 no-mask' />
                    <h1 className="text-3xl md:text-6xl font-bold tracking-widest text-center md:text-start ">
                        Sobre mí
                    </h1>
                </div>
                <div className='flex gap-2 flex-col'>
                    <p className='text-black font-medium md:w-[90%] text-justify md:text-start tracking-widest'>
                        Soy Jorge Daccarett, Ingeniero Civil Industrial de la Universidad Católica y MBA de la Universidad Adolfo Ibáñez. Con experiencia en gestión internacional y diplomacia, he trabajado en la Cancillería durante los gobiernos del ex-Presidente Sebastián Piñera, destacando como Director de la Agencia de Cooperación Internacional y Embajador de Chile en Emiratos Árabes Unidos, Arabia Saudita y Qatar.
                        <br />
                        Comprometido con la educación, también soy profesor en la Facultad de Negocios de la Universidad de Los Andes. Ahora, con la experiencia adquirida en 105 países, quiero poner mi conocimiento al servicio de los vecinos de Las Condes, con el objetivo de convertir nuestra comuna en un referente de clase mundial.
                        <br />
                        <br />
                        Jorge Daccarett<br />
                        Candidato Concejal<br />
                        Las Condes<br />

                    </p>
                </div>
            </div>

        </div>
    )
}

export default AboutUsSection