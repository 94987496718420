import React, { useEffect, useState } from 'react'
import { images } from './data/images'
import { motion } from 'framer-motion'

function ImagesComponents() {

    const [imagesBg, setImagesBg] = useState(images)

    var chooseMines = function (num) {
        var num = num || 100;
        var currentNum = 0;
        var mines = [];
        for (var i = 0; i < 10; i++) {
            do {
                currentNum = Math.ceil(Math.random() * num);
            } while (mines.includes(currentNum))
            mines.push(currentNum);
        }
        return mines;
    }

    const randomImages = () => {

        const generate = chooseMines(15)
        let imagesRandom = images.filter((image) => generate.includes(image.id))




        setImagesBg(imagesRandom)
    }


    useEffect(() => {

        const interval = setInterval(() => {
            randomImages()
        }, 8000)
        return () => clearInterval(interval)
    }, [])


    return (
        <div className='absolute top-0 w-full '>
            <div className='bg-background h-screen flex items-center w-full absolute  overflow-hidden opacity-95 '>
            </div>
            <div className='w-full grid grid-cols-1 md:grid-cols-5 transition-all'>
                {imagesBg.map((image) => (
                    <motion.img initial={{ opacity: 0 }} animate={{ opacity: 0.1 }} transition={{ duration: 2 }} key={image.id} src={image.src} alt='Caricom' className='h-[500px] w-[500px] object-cover object-center backdrop-blur-sm' />
                ))}

            </div>
        </div>
    )
}

export default ImagesComponents