import AboutUsSection from "./components/aboutus";
import Footer from "./components/footer";
import HeroSection from "./components/herohomesection";
import MetasSection from "./components/metas";


function App() {
  return (
    <>
      <HeroSection />
      <AboutUsSection />
      <MetasSection />
      <Footer />
    </>
  );
}

export default App;
