export const images = [
    {
        id: 1,
        src: '/assets/Caricom.jpg'
    },
    {
        id: 2,
        src: '/assets/australia.jpg'
    },
    {
        id: 3,
        src: '/assets/cepal.jpg'
    },
    {
        id: 4,
        src: '/assets/IMG_1728.jpg'
    },
    {
        id: 5,
        src: '/assets/IMG_1747.jpg'
    },
    {
        id: 6,
        src: '/assets/IMG_1732.jpg'
    },
    {
        id: 7,
        src: '/assets/IMG_1733.jpg'
    },
    {
        id: 8,
        src: '/assets/IMG_1734.jpg'
    },
    {
        id: 9,
        src: '/assets/IMG_1736.jpg'
    },
    {
        id: 10,
        src: '/assets/IMG_1737.jpg'
    },
    {
        id: 11,
        src: '/assets/IMG_1738.jpg'
    },
    {
        id: 12,
        src: '/assets/IMG_1739.jpg'
    },
    {
        id: 13,
        src: '/assets/IMG_1740.jpg'
    },
    {
        id: 14,
        src: '/assets/IMG_1742.jpg'
    },
    {
        id: 15,
        src: '/assets/IMG_1744.jpg'
    },

]